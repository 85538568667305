@keyframes scrolling{
	0%{
		transform: translateY(-2px);
	}
	50%{
		transform: translateY(2px);
	}
	100%{
		transform: translateY(-2px);
	}
}

/*
============================================
			MOUSE SCROLL ICON
============================================
*/
.how-refis-works-section{
  margin-top: -45px;
}
.scroll-down{
  z-index: 101;
  cursor: pointer;
  position: absolute;
  right: 30px;
  top: -85px;
  text-align: center;
	opacity: 0.5;
	@include desktop {
    display: none;
  }
  img{
    width: 12px;
    height: auto;

    animation-name: scrolling;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-out;
  }
}
.MouseWrapper{
  position: relative;
  width: 100%;
  text-align: center;
  //margin-top: -75px;
  //height: 70px;
  // left: 50%;
  // padding: 20px 30px;
  // background: #FFF;
  // border-radius: 50%;
  //margin-left: -46px;
}
.mouse{
  transform: scale(.65);
  width: 33px;
  height: 60px;
  border-radius: 60px;
  border: solid 3px $white;
  //margin-left: -15px;
  //background: #FFF;
  .scroller{
    width: 100%;
    text-align: center;
    position: absolute;
    left: 0;
    top: 15px;
    .scroll-wheel {
      width: 5px;
      height: 9px;
      border-radius: 3px;
      background-color: $white;
      display: block;
      margin: 0 auto;
      //animation-name: scrolling;
      //animation-duration: 1s;
      //animation-iteration-count: infinite;
      //animation-timing-function: ease-out;
    }
  }
}


// ==========================================================================
// Header
// ==========================================================================
.Header {
  position: absolute;
  z-index: $zindex-fixed;
  top: 32px;
  left: 0;
  width: 100%;
  color: $white;
  background: $secondary;
  box-shadow: 0 0 1rem rgba($box-shadow, .1);
  transition: box-shadow 250ms ease-in-out;
	@include desktop {
		top: auto;
	}
	.Header-container {
		//background: rgba(0,0,0,0.7);
    //border-radius: 50px;
		@include desktop {
			border-radius: 0;
		}
  }
}

.Header--transparent {
  background: none;
  box-shadow: none;
  color: $black;

  &.is-fixed {
    position: fixed;
    top: 0;
    background: $white !important;
    box-shadow: 0 0 3rem rgba($box-shadow, .1);

  }

  // .Header-logo {
  //   &::before {
  //     @extend .Logo-image;
  //     content: '';

  //   }
  //
}
.Header-row {
	margin: 0 -11px 0 0px !important;
}
.Header-logo {
  display: block;
  padding: 12px 0 12px 5px;
  margin-left: -1rem;
}
.Header-navInner {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}
.Header-menu {
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;

  > li {

    > a {
      display: flex;
      align-items: center;
      padding: .5rem 1rem;
			text-decoration: none;
			border-bottom: 2px solid $white;
			transition: 500ms;
      &:not(.btn) {
        // font-family: $font-family-secondary;
        color: inherit;
      }
			&:hover {
        border-bottom: 2px solid $primary;
				transition: 500ms;
      }
    }

    &.is-active {
			a {
	      font-weight: 700;
				border-bottom: 2px solid $black;
	    }
    }
  }
}
.Header-lang {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    height: 1rem;
    margin-top: -.5rem;
    border-left: 1px solid;
    vertical-align: middle;
  }
}
.Header-demo {
  margin-left: .5rem;
	a {
	  border: 0;
		border-radius: 30px;
		background: $primary;
		color: $white;
		border: 0 !important;
		&:hover {
			background: $black;
			color: $white;
			border: 0 !important;
		}
	}
}

.Header-navToggle {
  height: $header-height;
  padding: 1rem 1rem;
  margin-right: -1rem;
  font-family: $font-family-primary;
  line-height: 1;
	&:focus {
		box-shadow: none;
	}
  &.is-active {
    background: $white;
		&:focus {
			box-shadow: none;
		}
  }
}
.Header-navToggleIcon {
  display: block;
  color: $secondary;
  vertical-align: -.4em;
  margin-right: .25rem;
}

@include media-breakpoint-up(lg) {
  .Header-nav {
    display: block !important;
  }
}
@include media-breakpoint-down(md) {
  body.is-navOpen {
    overflow: hidden;
  }

  .Header {
    transform: none !important;
  }
  .Header-nav {
    display: none;
    position: fixed;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $white;
    text-align: center;
  }
  body.is-navOpen .Header-nav {
    overflow-x: hidden;
    overflow-y: auto;
  }
  .Header-navInner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    height: auto;

    transform: translate(0, -30px);
    transition: transform 250ms ease-out, opacity 250ms ease-out;;
  }
  .Header-nav.is-shown .Header-navInner {
    transform: translate(0, 0);
  }
  .Header-menu {
    flex: 0 0 auto;
    display: block;
    height: auto;
    padding: 1rem 0;
    text-align: center;

    > li {

      &:not(:last-child) {
        margin-bottom: .5rem;
      }

      > a {
        display: block;
      }
    }
  }
  .Header-lang {

    &:before {
      position: static;
      width: 1rem;
      height: auto;
      border: none;
      border-top: 1px solid;
      margin: 0 auto .5rem;
    }
  }
}
