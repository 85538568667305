// stylelint-disable selector-no-qualifying-type

//
// Base styles
.btn {
  display: inline-block;
  font-weight: $btn-font-weight;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: $btn-border-width solid transparent;
  @include button-size($btn-padding-y, $btn-padding-x, $font-size-base, $btn-line-height, $btn-border-radius);
  @include transition($transition-base);

  // font-size: $btn-font-size;
  background-color: transparent;

  // Share hover and focus styles
  @include hover-focus {
    text-decoration: none;
  }

  &:focus,
  &.focus {
    outline: 0;
    box-shadow: $btn-focus-box-shadow;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    opacity: $btn-disabled-opacity;
    @include box-shadow(none);
  }

  // Opinionated: add "hand" cursor to non-disabled .btn elements
  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    @include box-shadow($btn-active-box-shadow);

    &:focus {
      @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
    }
  }
}

// Future-proof disabling of clicks on `<a>` elements
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}


//
// Alternate buttons
//

.btn-primary,
.btn-secondary {
  position: relative;
  z-index: 0;
  overflow: hidden;
  border-color: transparent;
  background: none;

  &:before {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 200%;
    height: 100%;
    transform: translateX(-50%);
    transition: $transition-base;
  }

  @include hover-focus {
    &:before {
      transform: translateX(0);
    }
  }
}
.btn-primary {
  @include button-variant($primary, $primary);

  &:before {
    background: linear-gradient(to right, $primary-light, $primary 50%, $primary 50%, $primary-light);
  }
}

.btn-secondary {
  @include button-variant($secondary, $secondary);


  &:before {
    background-image: linear-gradient(45deg, $secondary, $secondary-dark 50%, $secondary-dark 50%, $secondary);
  }
}

.btn-white {
  @include button-variant($white, $white);
  color: $primary;

  // &:hover,
  // &:focus {
  //   color: $primary;
  // }
}

// .btn-light {
//   @include button-variant($light, $light);
//   color: $text-color;
// }

// .btn-transparent {
//   @include button-variant(transparent, transparent, $light, transparent, $gray-200, transparent);
//   color: $text-color;
// }


.btn-outline-primary {
  @include button-outline-variant($primary);
}

.btn-outline-secondary {
  @include button-outline-variant($secondary);
}

.btn-outline-white {
  @include button-outline-variant($white);

}

//
// Link buttons
//

// Make a button look and behave like a link
.btn-link {
  padding: 0;
  // font-weight: $font-weight-normal;
  color: $link-color;
  font-family: $font-family-primary;
  background-color: transparent;

  @include hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
    background-color: transparent;
    border-color: transparent;
  }

  &:focus,
  &.focus {
    text-decoration: $link-hover-decoration;
    border-color: transparent;
    box-shadow: none;
  }

  &:disabled,
  &.disabled {
    color: $btn-link-disabled-color;
    pointer-events: none;
  }

  // No need for an active state here
}

.btn-link-default {
  font-size: 1em;
  text-transform: none;
  letter-spacing: 0;
}

.btn-link-primary {
  color: $primary;
  font-size: 0.875rem;
  text-transform: none;
  letter-spacing: 0;
  text-decoration: underline;

}

.btn-shadow {
  box-shadow: 0 2px 36px rgba($primary,   .32);
}


//
// Button Sizes
//

.btn-lg {
  @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-line-height-lg, $btn-border-radius-lg);
}

.btn-sm {
  @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-line-height-sm, $btn-border-radius-sm);
}

.btn-wide {
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}
.btn-narrow {
  padding-right: 1rem;
  padding-left: 1rem;
}

//
// Block button
//

.btn-block {
  display: block;
  width: 100%;

  // Vertically space out multiple block buttons
  + .btn-block {
    margin-top: $btn-block-spacing-y;
  }
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}

.btn-ui {
  padding: ($btn-padding-y - 0.5rem*$btn-line-height/2) 0.5625rem;
  font-size: 1.25rem;

  &.btn-sm {
    padding: ($btn-padding-y-sm - 0.5rem*$btn-line-height-sm/2) $btn-padding-x-sm;
  }
  &.btn-lg {
    padding: ($btn-padding-y-lg - 0.5rem*$btn-line-height-lg/2) 0.875rem;
  }
}

.btn-icon-right {
  margin-left: 0.375rem;
}
.btn-icon-left {
  margin-right: 0.375rem;
}



.btn-collapse[aria-expanded="false"] {
  .btn-collapse-active {
    display: none;
  }
}

.btn-collapse[aria-expanded="true"] {
  .btn-collapse-default {
    display: none;
  }
  .btn-collapse-active {
    display: inline;
  }
}
