$icomoon-font-family: "refis" !default;
$icomoon-font-path: "fonts" !default;

$icon-credit-card: "\e90b";
$icon-calculator: "\e90a";
$icon-location: "\e907";
$icon-mail: "\e908";
$icon-phone: "\e909";
$icon-cancel: "\e900";
$icon-check: "\e901";
$icon-clock: "\e902";
$icon-cross: "\e903";
$icon-hourglass: "\e904";
$icon-star: "\e905";
$icon-suitcase: "\e906";

